import React from "react";
import { Link } from "react-router-dom";
import '../assets/style/hero.css'
import { Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";

export function HeroForm(hero, setHero) {
    const handleChangeHero = (event => {
        const key = event.target.name
        const value = event.target.value
        setHero({ ...hero, [key]: value })
    })
    return (
        <>
            <FormGroup>
                <FormLabel>Titre</FormLabel>
                <FormControl type="text" name="title" value={hero.title} onChange={handleChangeHero} required />
            </FormGroup>
            <FormGroup>
                <FormLabel>Image</FormLabel>
                <FormControl type="text" name="backgroundurl" value={hero.backgroundurl} onChange={handleChangeHero} required />
            </FormGroup>
            <FormGroup>
                <FormLabel>Sous-titre</FormLabel>
                <FormControl type="text" name="subtitle" value={hero.subtitle} onChange={handleChangeHero} required />
            </FormGroup>
            <FormGroup>
                <FormLabel>Lien bouton 1</FormLabel>
                <FormControl type="text" name="btn1url" value={hero.btn1url} onChange={handleChangeHero} required />
            </FormGroup>
            <FormGroup>
                <FormLabel>Message bouton 1</FormLabel>
                <FormControl type="text" name="btn1value" value={hero.btn1value} onChange={handleChangeHero} required />
            </FormGroup>
            <FormGroup>
                <FormLabel>Lien bouton 2</FormLabel>
                <FormControl type="text" name="btn2url" value={hero.btn2url} onChange={handleChangeHero} required />
            </FormGroup>
            <FormGroup>
                <FormLabel>Message bouton 2</FormLabel>
                <FormControl type="text" name="btn2value" value={hero.btn2value} onChange={handleChangeHero} required />
            </FormGroup>
        </>
    )
}

export function Hero(hero) {
    let backgroundimage = "linear-gradient(rgba(255,251,238, 0.5), rgba(255,251,238, 0.5)),url(\"" + hero.backgroundurl + "\")"
    let backgroundh1 = "linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0, 0.5)),url(\"/img/cicada.png\")"

    let colortitle = hero.colortitle
    let colorsubtitle = hero.colorsubtitle
    let fontsizetitle = hero.fontsizetitle
    let fontsizesubtitle = hero.fontsizesubtitle
    console.log(backgroundimage)
    return (
        <section key={hero.id} className="hero d-flex align-items-center" style={{ backgroundImage: backgroundimage }}>
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <div className="col-6 d-flex flex-column justify-content-center hero-content">
                            <img src="/img/logomarion.png" style={{ width: "80%", height: "auto" }}></img>
                            <div className="d-flex justify-content-center justify-content-lg-start align-content-center">
                                <Link to={hero.btn2url} target="_blank" className="btn-signin scrollto">{hero.btn2value}
                                    <box-icon name='right-arrow-alt' style={{ verticalAlign: 'top' }} color='#FFFFFF' />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="col-12" style={{ textAlign: "center" }}>
                            <h1 style={{ color: colortitle, fontSize: '30px', fontWeight: "bold" }}>{hero.title}</h1>
                            <h1 style={{ color: colortitle, fontSize: '30px' }}>{hero.date}</h1>
                            <h1 style={{ color: colortitle, fontSize: '30px', textTransform: "uppercase", fontWeight: "bold" }}>{hero.subtitle}</h1>
                        </div>
                        <div className="row">
                            <div className="col-6" style={{ backgroundImage: "url(/img/cicada25.png)", backgroundSize: '100% auto', backgroundPosition: "center", backgroundRepeat: "no-repeat", textAlign: "center" }}>
                                <h1 style={{ color: colortitle, fontSize: "25px", fontWeight: "bold" }}>23 Mars 17:30</h1>
                                <h1 style={{ color: "#000", fontSize: "30px", textTransform: "uppercase" }}>Magellan</h1>
                                <h1 style={{ color: "#000", fontSize: "20px", textTransform: "uppercase" }}>Eglise de Saint Martin de Crau</h1>
                            </div>
                            <div className="col-6" style={{ backgroundImage: "url(/img/cicada25.png)", backgroundSize: '100% auto', backgroundPosition: "center", backgroundRepeat: "no-repeat", textAlign: "center" }}>
                                <h1 style={{ color: colortitle, fontSize: "25px", fontWeight: "bold" }}>29 Mars 20:00</h1>
                                <h1 style={{ color: "#000", fontSize: "30px", textTransform: "uppercase" }}>CELLOMANIA</h1>
                                <h1 style={{ color: "#000", fontSize: "20px", textTransform: "uppercase" }}>Eglise Saint Pierre Saint Paul</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}